import { Media } from '@twilio/conversations';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { FileContent } from 'src/components/FileContent/FileContent';
import { useElementOnScreen } from 'src/hooks/useElementOnScreen';

const selector = {
  fileContainer: 'chat-file-contianer',
  fileName: 'chat-file-name',
  fileSize: 'chat-file-size',
};

type Props = {
  file: Media;
};

export function ChatAttachment({ file }: Props) {
  const [fileUrl, setFileUrl] = useState('');
  const { contentType, filename } = file || {};
  const { containerRef, isVisible } = useElementOnScreen<HTMLParagraphElement>({ threshold: 0.5 });

  useEffect(() => {
    const getUrl = async () => {
      const url = await file.getContentTemporaryUrl();

      setFileUrl(url ?? '');
    };

    //only load the file if it is visible otherwise it might flood the network
    if (file && isVisible) {
      getUrl();
    }
  }, [file, isVisible]);

  return (
    <FileContainer data-cy={selector.fileContainer}>
      <FileContent type={contentType} url={fileUrl} />

      <p ref={containerRef} data-cy={selector.fileName}>
        {filename}
      </p>
    </FileContainer>
  );
}

const FileContainer = styled.div`
  text-decoration: none !important;
  max-width: 700px;
  padding-top: 1em;
`;
