import { ArticleCollection } from './patient/Patients';

export type Clinic = {
  ID: string;
  post_title: string;
  address: {
    address: string;
    state: string;
    city: string;
    country: string;
  };
};

export type Role = {
  RoleID: number;
  Name: string;
  Description: string;
};

export type NoteTopic = {
  TopicID: number;
  TopicName: string;
  TopicDescription: string;
};

export type Treatment = {
  TreatmentID: string;
  Treatment: string;
};

export type ClinicMetadata = {
  roles: Role[];
  noteTopics: NoteTopic[];
  treatments: Treatment[];
};

type AggregateDataValue = number | null;

export type ClinicStats = {
  hospitalid: number;
  calc_date?: string;
  total_patients: number;
  active_patients: number;
  avg_4mo_tbwl: number;
  daily_weigh_rate_clinic: number;
  weekly_weigh_rate_clinic: number;
  monthly_weigh_rate_clinic: number;
  median_tbwl_mo1?: AggregateDataValue;
  median_tbwl_mo10?: AggregateDataValue;
  median_tbwl_mo11?: AggregateDataValue;
  median_tbwl_mo12?: AggregateDataValue;
  median_tbwl_mo2?: AggregateDataValue;
  median_tbwl_mo3?: AggregateDataValue;
  median_tbwl_mo4?: AggregateDataValue;
  median_tbwl_mo5?: AggregateDataValue;
  median_tbwl_mo6?: AggregateDataValue;
  median_tbwl_mo7?: AggregateDataValue;
  median_tbwl_mo8?: AggregateDataValue;
  median_tbwl_mo9?: AggregateDataValue;
  fifth_perc_tbwl_mo1?: AggregateDataValue;
  fifth_perc_tbwl_mo10?: AggregateDataValue;
  fifth_perc_tbwl_mo11?: AggregateDataValue;
  fifth_perc_tbwl_mo12?: AggregateDataValue;
  fifth_perc_tbwl_mo2?: AggregateDataValue;
  fifth_perc_tbwl_mo3?: AggregateDataValue;
  fifth_perc_tbwl_mo4?: AggregateDataValue;
  fifth_perc_tbwl_mo5?: AggregateDataValue;
  fifth_perc_tbwl_mo6?: AggregateDataValue;
  fifth_perc_tbwl_mo7?: AggregateDataValue;
  fifth_perc_tbwl_mo8?: AggregateDataValue;
  fifth_perc_tbwl_mo9?: AggregateDataValue;
  twenty_fifth_perc_tbwl_mo1?: AggregateDataValue;
  twenty_fifth_perc_tbwl_mo10?: AggregateDataValue;
  twenty_fifth_perc_tbwl_mo11?: AggregateDataValue;
  twenty_fifth_perc_tbwl_mo12?: AggregateDataValue;
  twenty_fifth_perc_tbwl_mo2?: AggregateDataValue;
  twenty_fifth_perc_tbwl_mo3?: AggregateDataValue;
  twenty_fifth_perc_tbwl_mo4?: AggregateDataValue;
  twenty_fifth_perc_tbwl_mo5?: AggregateDataValue;
  twenty_fifth_perc_tbwl_mo6?: AggregateDataValue;
  twenty_fifth_perc_tbwl_mo7?: AggregateDataValue;
  twenty_fifth_perc_tbwl_mo8?: AggregateDataValue;
  twenty_fifth_perc_tbwl_mo9?: AggregateDataValue;
  seventy_fifth_perc_tbwl_mo1?: AggregateDataValue;
  seventy_fifth_perc_tbwl_mo10?: AggregateDataValue;
  seventy_fifth_perc_tbwl_mo11?: AggregateDataValue;
  seventy_fifth_perc_tbwl_mo12?: AggregateDataValue;
  seventy_fifth_perc_tbwl_mo2?: AggregateDataValue;
  seventy_fifth_perc_tbwl_mo3?: AggregateDataValue;
  seventy_fifth_perc_tbwl_mo4?: AggregateDataValue;
  seventy_fifth_perc_tbwl_mo5?: AggregateDataValue;
  seventy_fifth_perc_tbwl_mo6?: AggregateDataValue;
  seventy_fifth_perc_tbwl_mo7?: AggregateDataValue;
  seventy_fifth_perc_tbwl_mo8?: AggregateDataValue;
  seventy_fifth_perc_tbwl_mo9?: AggregateDataValue;
  ninety_fifth_perc_tbwl_mo1?: AggregateDataValue;
  ninety_fifth_perc_tbwl_mo10?: AggregateDataValue;
  ninety_fifth_perc_tbwl_mo11?: AggregateDataValue;
  ninety_fifth_perc_tbwl_mo12?: AggregateDataValue;
  ninety_fifth_perc_tbwl_mo2?: AggregateDataValue;
  ninety_fifth_perc_tbwl_mo3?: AggregateDataValue;
  ninety_fifth_perc_tbwl_mo4?: AggregateDataValue;
  ninety_fifth_perc_tbwl_mo5?: AggregateDataValue;
  ninety_fifth_perc_tbwl_mo6?: AggregateDataValue;
  ninety_fifth_perc_tbwl_mo7?: AggregateDataValue;
  ninety_fifth_perc_tbwl_mo8?: AggregateDataValue;
  ninety_fifth_perc_tbwl_mo9?: AggregateDataValue;
};

export type ClinicSettings = {
  ClinicID: string;
  features?: string[];
  weightUnit?: string;
  treatmentsOffered?: number[];
};

export type ParsedClinicSettings = Omit<ClinicSettings, 'treatmentsOffered'> & {
  treatmentsOffered?: string[];
};

export type ParsedClinicData = {
  id: string;
  value: string;
  label: string;
  clinicName: string;
  city: string;
  country: string;
};

export function parseClinicsData(data?: Clinic[]): ParsedClinicData[] {
  return (data ?? []).map((d) => ({
    id: d.ID,
    value: d.ID,
    label: d.post_title,
    clinicName: d.post_title,
    city: d.address.city,
    country: d.address.country,
  }));
}

const frenchClinics: string[] = [
  '6396',
  '8088',
  '4388',
  '4367',
  '4588',
  '4155',
  '4182',
  '4201',
  '6043',
  '6049',
  '6050',
  '4202',
  '4224',
  '4225',
  '6254',
  '6059',
  '9102',
  '5002',
  '5035',
  '5036',
  '4523',
  '4942',
  '6296',
  '6303',
  '6456',
  '6077',
  '6090',
  '8113',
  '8114',
  '8115',
  '8118',
  '8119',
  '8120',
  '8122',
  '8123',
  '8124',
  '8127',
  '6498',
  '8145',
  '6531',
  '6534',
  '8857',
  '6562',
  '8128',
  '8129',
  '8130',
  '8131',
  '8132',
  '8144',
  '8152',
  '8153',
  '8154',
  '8416',
  '8458',
  '8482',
  '8003',
  '8312',
  '8915',
  '6409',
  '8493',
  '8528',
  '8530',
  '8729',
  '9052',
  '9070',
  '9072',
  '9075',
  '3684',
  '3687',
  '3688',
  '3689',
  '3709',
  '3711',
  '3712',
  '3714',
  '3693',
  '3702',
  '3704',
  '3705',
  '3706',
  '3707',
  '3708',
  '4074',
  '4088',
  '4095',
  '6125',
  '4040',
  '8194',
  '4041',
  '4477',
  '4570',
  '4571',
  '4748',
  '5069',
  '8116',
  '9025',
  '9027',
  '8863',
  '9112',
  '3939',
  '3996',
  '4096',
  '4572',
  '3690',
  '3691',
  '3692',
  '3713',
  '4200',
  '4810',
  '4457',
  '4509',
  '4721',
  '5020',
  '5058',
  '6145',
  '6330',
  '6373',
  '6461',
  '6559',
  '7982',
  '8072',
  '8117',
  '8121',
  '8133',
  '8134',
  '8135',
  '8136',
  '8137',
  '8138',
  '8139',
  '8140',
  '8141',
  '8143',
  '8171',
  '8337',
  '8460',
  '8834',
  '3695',
  '3696',
  '3698',
  '3699',
  '3701',
  '3944',
  '4011',
  '4356',
  '3686',
  '3703',
  '4203',
  '6292',
  '8125',
  '6554',
  '6620',
  '8126',
  '3697',
  '6122',
  '8325',
  '8305',
  '6311',
  '8439',
  '9056',
  '6146',
  '6315',
  '8192',
  '8142',
  '8290',
];

export function filterFrenchCollection(clinicId: string) {
  const isFrenchClinic = frenchClinics.includes(clinicId);

  return (collection: ArticleCollection) =>
    isFrenchClinic || collection.id === '6uLIpT5dEzVCJSxg8xSBCa';
}
