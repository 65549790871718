import { isAdmin, isCoach, isSuperAdmin } from '@allurion/domain';
import { Auth } from 'aws-amplify';
import { useIntl } from 'react-intl';

import { useTrackEvent } from 'src/analytics/analytics';
import { getNeedsToSignDataAgreement } from 'src/api/ProviderApi';
import { updateProfile } from 'src/api/UserApi';
import { toastWarning } from 'src/components/ui/toasts';
import { Provider } from 'src/domain/Provider';
import { Unit, User } from 'src/domain/User';
import { useAppNavigate } from 'src/hooks/useAppNavigate';
import messages from 'src/messages/auth.messages';
import { useAppDispatch, useAppSelector } from 'src/state/store';
import { getCurrentUser, UserActions } from 'src/state/userSlice';
import TwilioConversationsService from 'src/twilio/TwilioConversationsService';

import { preloadCurrentProvider } from './useCurrentProvider';

export function useCurrentUser() {
  const user = useAppSelector(getCurrentUser);
  const dispatch = useAppDispatch();
  const { toLoginPage, toHomePage, toTermsAndConditionsPage, navigateAndScrollToTop } =
    useAppNavigate();
  const intl = useIntl();
  const { trackLogin, trackLogout } = useTrackEvent();

  function setUser(userData: User) {
    dispatch(UserActions.setUser(userData));
  }

  async function logout({ showSessionTimeoutWarning = false, replace = false } = {}) {
    TwilioConversationsService.disconnect();
    localStorage.clear();
    sessionStorage.clear();

    if (user.sessionToken) {
      await Auth.signOut({ global: true });
      trackLogout({ providerId: user.username });
      dispatch(UserActions.clearAll());
      if (showSessionTimeoutWarning) {
        toastWarning(intl.formatMessage(messages.sessionTimeout));
      }
    }
    toLoginPage(true, { replace });
  }

  async function login({
    email,
    password,
    redirectTo,
  }: {
    email: string;
    password: string;
    redirectTo?: string | null;
  }) {
    const userResponse = await Auth.signIn(email, password);

    const sessionToken = userResponse?.signInUserSession?.idToken?.jwtToken;
    const emailVerified = userResponse?.attributes?.email_verified;

    const provider = await preloadCurrentProvider();

    const username = provider?.CognitoUserName;
    const firstName = provider?.FirstName;
    const lastName = provider?.LastName;
    const roleId = provider?.RoleID;
    const unitsPreference = provider?.UnitsPreference;
    const providerId = provider?.ProviderID;
    const notificationsEnabled = provider?.notifications_enabled;
    const notificationsFrequency = provider?.notification_frequency;

    if (isSuperAdmin(roleId)) {
      throw new Error(`UserRoleLoginNotAllowed:SuperAdmin:${providerId}`);
    }

    if (isCoach(roleId)) {
      throw new Error(`UserRoleLoginNotAllowed:Coach:${providerId}`);
    }

    const lastClinicId = sessionStorage.getItem('clinicId');

    if (!lastClinicId) {
      sessionStorage.setItem('clinicId', provider.ClinicID.toString());
    }

    setUser({
      sessionToken,
      username,
      email,
      emailVerified,
      roleId,
      firstName,
      lastName,
      unitsPreference,
      providerId,
      notificationsEnabled,
      notificationsFrequency,
    });
    trackLogin({ providerId });
    TwilioConversationsService.disconnect();

    if (await shouldAcceptPolicy(roleId, provider)!) {
      toTermsAndConditionsPage();
    } else if (redirectTo) {
      navigateAndScrollToTop(redirectTo, { replace: true });
    } else {
      toHomePage();
    }
  }

  async function update({
    firstName,
    lastName,
    unitsPreference,
    notificationsEnabled,
    notificationsFrequency,
  }: UpdateProps) {
    await updateProfile({
      firstName,
      lastName,
      unitsPreference,
      notificationsEnabled,
      notificationsFrequency,
    });
    setUser({
      ...user,
      firstName,
      lastName,
      unitsPreference,
      notificationsEnabled,
      notificationsFrequency,
    });
  }

  return {
    user,
    unitsPreference: (user?.unitsPreference ?? 'kg') as Unit,
    isAuthenticated: !!user?.sessionToken,
    userRole: user?.roleId,
    login,
    logout,
    update,
  };
}

type UpdateProps = {
  firstName: string;
  lastName: string;
  unitsPreference: string;
  notificationsEnabled: boolean;
  notificationsFrequency?: string;
};

async function shouldAcceptPolicy(roleId: string, provider: Provider) {
  const noNeedtoAcceptPolicy = !isAdmin(roleId) || provider.AcceptPolicy;

  if (noNeedtoAcceptPolicy) {
    return false;
  }

  const { shouldSignDataAgreement } = await getNeedsToSignDataAgreement();

  return shouldSignDataAgreement;
}
