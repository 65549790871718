import { ADMIN_ROLE_ID, STAFF_ROLE_ID } from '@allurion/domain';
import { useMemo } from 'react';

import { Provider, ProviderMultipleClinics } from 'src/domain/Provider';

import { useProviders } from './useClinicProviders';
import { useCurrentProvider } from './useCurrentProvider';

export type ManageUsersClinics = {
  city: string;
  clinicName: string;
  country: string;
  id: string;
  label: string;
  value: string;
};

export type ClinicProvider = Omit<Provider, 'ClinicID'> & {
  ClinicID: string[];
  ClinicLabel: string;
};

export function useManageClinicsProviders(clinics: ManageUsersClinics[] = []) {
  const clinicIds = clinics?.map((c) => c.id);
  const { provider } = useCurrentProvider();
  const EmailAddress = provider?.EmailAddress;

  const { providers: fetchedProviders, isLoading } = useProviders(clinicIds);
  const filteredProviders = fetchedProviders.filter(
    (o) => o.RoleID === ADMIN_ROLE_ID || o.RoleID === STAFF_ROLE_ID
  );
  const clinicLabelsMap = useMemo<{ [key: string]: string }>(() => {
    if (!clinics || clinics.length === 0) {
      return {};
    }

    return clinics.reduce(
      (acc, clinic) => ({ ...acc, [clinic.id]: (clinic.label ?? '').trim() }),
      {}
    );
  }, [clinics]);
  const providers = useMemo(() => {
    const usersWithoutCurrentUser: ProviderMultipleClinics[] = filteredProviders.filter(
      (o) => o.EmailAddress !== EmailAddress && o.IsDisabled === false
    );

    const usersWithClinicLabels = usersWithoutCurrentUser.map((item) => ({
      ...item,
      ClinicLabel: clinicLabelsMap[item.ClinicID],
    }));

    const userEmails = usersWithClinicLabels.map((s) => s.EmailAddress);
    const uniqueEmails = [...new Set(userEmails)];

    const usersAggregatedByEmail = uniqueEmails.map((EmailAddress) => {
      const userProviders = usersWithClinicLabels.filter((s) => s.EmailAddress === EmailAddress);

      const clinicIDs = [...new Set(userProviders.map((c) => c.ClinicID))];

      return {
        ...userProviders[0],
        ClinicID: clinicIDs,
        ClinicLabel: clinicIDs
          .map((clinicID) => clinicLabelsMap[clinicID])
          .filter(Boolean)
          .sort((a, b) => (a < b ? -1 : 1)) // Alphabetical sorting
          .join('\n'),
      };
    });

    return usersAggregatedByEmail;
  }, [EmailAddress, clinicLabelsMap, filteredProviders]);

  return { isLoading, providers };
}
