import { isNull, isUndefined } from '@allurion/utils';

export enum HeightUnit {
  Cm = 'cm',
  In = 'in',
}

export function isHeightUnit(unit: string): unit is HeightUnit {
  return Object.values(HeightUnit).includes(unit as HeightUnit);
}

export function formatHeight(value: string | number | null | undefined, unit: HeightUnit) {
  if (isUndefined(value) || isNull(value)) {
    return '';
  }

  const numericValue = Number(value);

  switch (unit) {
    case HeightUnit.In:
      return cmToHeightInches(numericValue);

    default:
      return `${value} cm`;
  }
}

export function cmToHeightInches(numInCm: number) {
  const inches = numInCm / 2.54;
  const feet = Math.floor(inches / 12);
  const finalInches = Math.round(inches % 12);

  return `${feet}'${finalInches}"`;
}
