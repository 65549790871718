import { isString, isDate } from './is-type';

/**
 * Extract just the date portion of the string eg: from "2021-07-15 20:56:00" gets 2021-07-15""
 */
export function toDateStr(date: Date | string) {
  const str = isString(date) ? date : date.toISOString();

  return str.slice(0, 10);
}

export function getTodayDateStr(timeDifference = 0) {
  const utcDate = new Date();
  utcDate.setUTCMinutes(utcDate.getUTCMinutes() + timeDifference);
  return toDateStr(utcDate);
}

export function isToday(date: Date | string) {
  const today = getTodayDateStr();

  return toDateStr(date) === today;
}

export function dateAdd(date: Date, days: number) {
  const newdate = new Date(date.valueOf());
  newdate.setDate(newdate.getDate() + days);
  return newdate;
}

export function isIsoDatetime(str: string) {
  return /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?([+-]\d{2}:\d{2}|Z)$/.test(str);
}

export function isUTCTimestamp(str: string) {
  return /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/.test(str);
}

/**
 * If a date was stored with 00:00:00 in the DB, it probably means that the
 * timezone was not taken into account
 */
export function shouldIgnoreTimezone(dateStr: string) {
  return / 00:00:00$/.test(dateStr);
}

export function strToDate(str: string, ignoreTimezone = true) {
  const normalizedDateStr = ignoreTimezone && shouldIgnoreTimezone(str) ? toDateStr(str) : str;

  if (isIsoDatetime(normalizedDateStr)) {
    return new Date(normalizedDateStr);
  }

  if (isUTCTimestamp(normalizedDateStr)) {
    return new Date(`${normalizedDateStr}Z`);
  }

  const [year, month, day] = normalizedDateStr.split('-');

  if (!year || !month || !day) {
    throw new Error(`Invalid date string: ${str}`);
  }

  const date = new Date(+year, +month - 1, +day);
  // Set the time to noon to avoid timezone issues
  date.setUTCHours(11);

  return date;
}

/**
 * Gets a date object substracting one day
 */
export function getPreviousDate(date: Date) {
  const previousDate = new Date(date);

  previousDate.setDate(previousDate.getDate() - 1);

  return previousDate;
}

export function daysSince(targetDate: number | string) {
  const today = Date.now();
  const formattedDate = `${targetDate}`.replace(' ', 'T');
  const installDate = Date.parse(formattedDate);
  const diff = today - installDate;
  const days = Math.floor(diff / 1000 / 60 / 60 / 24);

  return days;
}

/**
 * Formats a date with day, month and year eg: 2021-07-15 -> 15/07/2021
 * according to the browser's locale
 */
export function toLocalizedDate(dateStr: string | undefined, locale: string) {
  if (!dateStr) {
    return '';
  }

  const dateObj = strToDate(dateStr);

  return dateObj.toLocaleDateString(locale, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
}

/**
 * Gets the day of the week in three letter format
 */
export function toLocalizedDayOfWeek(date: Date, locale: string) {
  const dayOfWeek = date.toLocaleDateString(locale, { weekday: 'short' });

  return dayOfWeek;
}

/**
 * Short date format eg: 'Tue, Sep 02'
 */
export function toLocalizedShortDate(dateStr: string, locale: string) {
  const dateObj = strToDate(dateStr);

  return dateObj.toLocaleDateString(locale, {
    weekday: 'short',
    month: 'short',
    day: '2-digit',
  });
}

/**
 * Long datetime format eg: Aug 15, 2018
 */
export function toLocalizedLongDate(dateStr: string | undefined, locale: string) {
  if (!dateStr) {
    return '';
  }

  const dateObj = strToDate(dateStr);

  return dateObj.toLocaleDateString(locale, {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
  });
}

/**
 * Long datetime format eg: Aug 15, 5:13 PM 08/15 • 05:13 PM
 */
export function toLocalizedLongDateTime(dateTimeStr: string, locale: string) {
  const dateObj = strToDate(dateTimeStr);

  const dateStr = dateObj.toLocaleDateString(locale, {
    weekday: 'long',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });

  const timeStr = dateObj.toLocaleTimeString(locale, {
    hour: '2-digit',
    minute: '2-digit',
  });

  return `${dateStr} • ${timeStr}`;
}

/**
 * Short datetime format eg: Aug 15, 5:13 PM
 */
export function toLocalizedShortDateTime(dateTimeStr: string | Date | undefined, locale: string) {
  if (!dateTimeStr) {
    return '';
  }

  const dateObj = isDate(dateTimeStr) ? dateTimeStr : strToDate(dateTimeStr, false);

  return dateObj.toLocaleDateString(locale, {
    month: 'short',
    day: '2-digit',
    hour: 'numeric',
    minute: '2-digit',
  });
}

export function toLocalizedTime(dateTimeStr: string | Date | undefined, locale: string) {
  if (!dateTimeStr) {
    return '';
  }

  const dateObj = isDate(dateTimeStr) ? dateTimeStr : strToDate(dateTimeStr, false);

  return dateObj.toLocaleTimeString(locale, {
    hour: 'numeric',
    minute: '2-digit',
  });
}

export function calculateAge(birthday: string) {
  const date = strToDate(birthday);
  const today = new Date();

  let age = today.getFullYear() - date.getFullYear();
  const month = today.getMonth() - date.getMonth();

  if (month < 0 || (month === 0 && today.getDate() < date.getDate())) {
    age--;
  }

  return age;
}
