import { isToday, toLocalizedShortDateTime, toLocalizedTime } from '@allurion/utils';
import { Media } from '@twilio/conversations';
import styled from 'styled-components';

import { ParseHyperlinks } from 'src/components/ParseHyperlinks';
import { UserAvatar } from 'src/components/UserAvatar';
import { useLocale } from 'src/hooks/useLocale';
import { ProviderColor, PatientColor } from 'src/styles/theme/colors';

import { ChatAttachment } from './ChatAttachment';
import { ChatReadIndicator } from './ChatReadIndicator';

const selector = {
  container: 'chat-message-container',
  info: 'chat-message-info',
  user: 'chat-message-user',
  date: 'chat-message-date',
};

type Props = {
  data: {
    name: string;
    message: string;
    date: Date | null;
    id: string;
    isProvider: boolean;
    isMe: boolean;
    attachment?: Media;
    index: number;
  };
  lastReadIndex?: number;
};

export function ChatMessage({ data, lastReadIndex }: Props) {
  const { locale } = useLocale();
  const { name, date, message, isProvider, isMe, attachment, index } = data || {};
  const avatarColor = isProvider ? ProviderColor : PatientColor;

  const formattedDate = date
    ? isToday(date)
      ? toLocalizedTime(date, locale)
      : toLocalizedShortDateTime(date, locale)
    : '-';

  return (
    <>
      <Container isMe={isMe} data-cy={selector.container}>
        <div className="avatar">
          <UserAvatar color={avatarColor} name={name} />
        </div>
        <div className="content">
          <div className="title" data-cy={selector.info}>
            <ChatUser data-cy={selector.user}>{name}</ChatUser>
            <ChatDate data-cy={selector.date}>{formattedDate}</ChatDate>
          </div>
          <div className="message">
            <ParseHyperlinks message={message} />
            {attachment && <ChatAttachment file={attachment} />}
          </div>
        </div>
      </Container>

      <ChatReadIndicator isProvider={isProvider} index={index} lastReadIndex={lastReadIndex} />
    </>
  );
}

const Container = styled.div<{ isMe: boolean }>`
  display: block;
  background-color: ${({ theme }) => theme.colors.Silver};
  color: ${({ theme }) => theme.colors.Primary};
  padding: 1.5em;
  margin-bottom: 1em;
  margin-right: 1em;
  align-self: ${({ isMe }) => (!isMe ? 'flex-start' : 'flex-end')};

  ${({ theme }) => theme.media.min.tablet`
    display: flex;
    max-width: 768px;
    width: 100%;
  `}

  .avatar {
    padding-right: 8px;
  }

  .content {
    padding-top: 16px;
    display: flex;
    flex-direction: column;
    flex: 1;

    ${({ theme }) => theme.media.min.tablet`
      padding-left: 8px;
      padding-top: 0px;
    `}

    .title {
      display: flex;
      justify-content: space-between;
    }

    .message {
    }
  }
`;

const ChatUser = styled.span`
  font-size: 16px;
  font-family: 'GT-America-Standard-Medium', sans-serif;
`;

const ChatDate = styled.span`
  font-size: 12px;
  padding-top: 6px;
  color: ${({ theme }) => theme.colors.DarkGrey};
`;
