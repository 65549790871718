import { enhanceError } from '@allurion/utils';
import { useEffect, useRef, useCallback } from 'react';

import { useTrackEvent } from 'src/analytics/analytics';
import { PatientProfile } from 'src/domain/patient/Patients';
import { useCurrentProvider } from 'src/hooks/useCurrentProvider';
import { Logger } from 'src/services/Logger';

import TwilioConversationsService from './TwilioConversationsService';

export function useSetupTwilioConversation(patient?: PatientProfile) {
  const { provider } = useCurrentProvider();
  const { trackTwilioChatFailure } = useTrackEvent();
  const conversationSetupRunning = useRef(false);
  const identity = provider.CognitoUserName;

  const patientId = patient?.uid;
  const patientUsername = patient?.userid;
  const providerName = `${provider?.FirstName} ${provider?.LastName}`;

  const setupConversation = useCallback(async () => {
    if (!patientUsername || !identity || !patientId) {
      return;
    }

    await TwilioConversationsService.setupConversation({
      identity,
      patientIdentity: patientUsername,
      providerName,
      patientId: patientId.toString(),
    }).catch((error) => {
      Logger.captureException(enhanceError(error, { prefix: 'useSetupTwilioConversation' }));
      trackTwilioChatFailure({ error });
    });
  }, [identity, patientId, patientUsername, providerName, trackTwilioChatFailure]);

  useEffect(() => {
    if (conversationSetupRunning.current) {
      return;
    }

    conversationSetupRunning.current = true;
    setupConversation();
  }, [setupConversation]);
}
