import { useIntl } from 'react-intl';
import styled from 'styled-components';

import checkIconSrc from 'src/images/check-icon.svg';

import messages from './chat.messages';

type Props = {
  isProvider: boolean;
  index: number;
  lastReadIndex?: number;
};

const selector = {
  label: 'chat-read-indicator-label',
};

export function ChatReadIndicator({ isProvider, index, lastReadIndex }: Props) {
  const intl = useIntl();
  const lastReadLabel = intl.formatMessage(messages.messageRead);
  const isVisible = isProvider && index === lastReadIndex;

  if (!isVisible) {
    return null;
  }

  return (
    <UnreadMessageLabel>
      <ReadCheck src={checkIconSrc} alt="check" />
      <ReadLabel data-cy={selector.label}>{lastReadLabel}</ReadLabel>
    </UnreadMessageLabel>
  );
}

const UnreadMessageLabel = styled.div<any>`
  display: inline-block;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.DarkGrey};
  margin-top: -12px;
  margin-bottom: 12px;
  font-family: 'GT-America-Standard-Light', sans-serif;
  width: 100%;
  text-align: right;
  padding-right: 2em;
`;

const ReadCheck = styled.img`
  vertical-align: bottom;
`;

const ReadLabel = styled.span`
  vertical-align: text-top;
`;
