import { PatientTreatment } from '@allurion/domain';
import { FormGroup, InputGroup, SelectInput } from '@allurion/ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';

import { TrackedButton } from 'src/analytics/TrackedUI';
import { Loader } from 'src/components/ui/Loader';
import { isValidStartDate } from 'src/domain/patient/treatment';
import { useClinicTreatements } from 'src/hooks/useClinicCustomTreatments';
import { useTreatmentLabels } from 'src/hooks/useTreatmentLabels';
import globalMessages from 'src/messages/global.messages';
import { usePatientFormatters } from 'src/pages/ClinicPage/ClinicPatientsPage/usePatientFormatters';

import translations from './PatientTreamentsCard.translations';

import styles from './TreatmentForm.module.scss';

export type TreatmentFormValues = Pick<
  PatientTreatment,
  'TreatmentTypeID' | 'StartDate' | 'EndDate' | 'TreatmentUserID'
>;

type Props = {
  treatment: TreatmentFormValues;
  onSave: (t: TreatmentFormValues) => void;
  onCancel: () => void;
  clinicId: string;
};

export function TreatmentForm({ clinicId, treatment, onSave, onCancel }: Props) {
  const intl = useIntl();
  const [isSaving, setIsSaving] = useState(false);
  const { getTreatmentCategoryLabel, getTreatmentLabel } = useTreatmentLabels(clinicId);
  const { treatments } = useClinicTreatements(clinicId);
  const { getTreatmentStartDateLabel } = usePatientFormatters(clinicId);

  const treatmentsOptions =
    treatments?.map((treatment) => {
      return {
        label: `${getTreatmentCategoryLabel(treatment.treatment_type_id, 'short')}
        /
        ${getTreatmentLabel(treatment.custom_treatment_id, 'short')}`,
        value: treatment.custom_treatment_id,
      };
    }) ?? [];

  const initialValues: Partial<PatientTreatment> = {
    StartDate: '',
    EndDate: undefined,
    CustomTreatmentID: 0,
    TreatmentUserID: 0,
  };

  const TreatmentSchema = Yup.object().shape({
    CustomTreatmentID: Yup.number()
      .moreThan(0, intl.formatMessage(globalMessages.required))
      .required(intl.formatMessage(globalMessages.required)),
    StartDate: Yup.string().required(intl.formatMessage(globalMessages.required)),
    EndDate: Yup.string().nullable(),
  });

  function isValidEndDate(endDateStr: string | undefined, startDateStr: string | undefined) {
    return !endDateStr || !startDateStr || endDateStr >= startDateStr;
  }

  const handleCancel = () => {
    onCancel();
  };

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    watch,
  } = useForm<PatientTreatment>({
    resolver: yupResolver(TreatmentSchema),
    defaultValues: treatment || initialValues,
  });

  const selectedTreatmentType = watch('CustomTreatmentID');
  const treatmentCategoryId = treatments?.find(
    (t) => t.custom_treatment_id === selectedTreatmentType.toString()
  )?.treatment_type_id;

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
      <Loader isLoading={isSaving} />

      <div className={styles.content}>
        <FormGroup
          label={intl.formatMessage(translations.treatmentType)}
          error={errors.CustomTreatmentID?.message}
        >
          <SelectInput {...register('CustomTreatmentID')} options={treatmentsOptions} />
        </FormGroup>

        <FormGroup
          label={getTreatmentStartDateLabel(treatmentCategoryId)}
          error={errors.StartDate?.message}
        >
          <InputGroup textInput={{ type: 'date', ...register('StartDate') }} />
        </FormGroup>

        <FormGroup
          label={intl.formatMessage(translations.treatmentEndDate)}
          error={errors.EndDate?.message}
        >
          <InputGroup textInput={{ type: 'date', ...register('EndDate') }} />
        </FormGroup>
      </div>

      <div className={styles.footer}>
        <TrackedButton
          label={intl.formatMessage(globalMessages.cancel)}
          type="button"
          onClick={handleCancel}
          variant="secondary"
          trackLabel="cancel-treatment-form"
        />
        <TrackedButton
          label={intl.formatMessage(translations.treatmentSave)}
          type="submit"
          trackLabel="submit-treatment-form"
        />
      </div>
    </form>
  );

  async function onSubmit(treatment: PatientTreatment) {
    if (treatment.StartDate && !isValidStartDate(treatment.StartDate)) {
      setError('StartDate', {
        type: 'custom',
        message: intl.formatMessage(translations.startDateErrorMessage),
      });

      return false;
    }

    if (treatment.EndDate && !isValidEndDate(treatment.EndDate, treatment.StartDate)) {
      setError('EndDate', {
        type: 'custom',
        message: intl.formatMessage(translations.endDateErrorMessage),
      });

      return false;
    }

    setIsSaving(true);

    await onSave({
      TreatmentUserID: treatment.TreatmentUserID,
      StartDate: treatment.StartDate,
      EndDate: treatment.EndDate,
      TreatmentTypeID: treatment.CustomTreatmentID,
    });

    setIsSaving(false);
  }
}
