import { InputSelector } from '@allurion/ui';
import { useMemo } from 'react';

import { useClinicTreatements } from 'src/hooks/useClinicCustomTreatments';
import { useTreatmentLabels } from 'src/hooks/useTreatmentLabels';

import styles from './ClinicSettingsPage.module.scss';

type Props = {
  clinicId: string;
  treatmentsOffered?: number[];
  onChange: (treatmentId: number, checked: boolean) => void;
};

export function ClinicOfferedTreatments({ clinicId, treatmentsOffered, onChange }: Props) {
  const { treatmentList } = useClinicTreatements(clinicId, true);
  const { getTreatmentLabel, getTreatmentCategoryLabel } = useTreatmentLabels(clinicId);

  const treatmentCategories = useMemo(
    () => [...new Set(treatmentList.map((treatment) => treatment.treatment_type_id))],
    [treatmentList]
  );
  const treatmentByCategory = useMemo(
    () =>
      treatmentCategories.reduce(
        (acc, category) => {
          const treatments = treatmentList
            .filter((treatment) => treatment.treatment_type_id === category)
            .map((treatment) => ({
              label: getTreatmentLabel(treatment.custom_treatment_id),
              value: parseInt(treatment.custom_treatment_id, 10),
              type: treatment.custom_treatment_name,
            }));

          return {
            ...acc,
            [category]: treatments,
          };
        },
        {} as Record<number, { label: string; value: number; type: string }[]>
      ),
    [getTreatmentLabel, treatmentCategories, treatmentList]
  );

  return (
    <div className={styles.treatments} data-cy="clinic-settings-treatments">
      {treatmentCategories.map((category) => (
        <div key={category} className={styles.treatment}>
          <div className={styles.title}>{getTreatmentCategoryLabel(category)}</div>
          {treatmentByCategory[category].map((treatment) => (
            <InputSelector
              type="checkbox"
              label={treatment.label}
              checked={treatmentsOffered?.includes(treatment.value)}
              key={treatment.value}
              onChange={({ currentTarget: { checked } }) => onChange(treatment.value, checked)}
            />
          ))}
        </div>
      ))}
    </div>
  );
}
