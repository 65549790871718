import { isPatientGroupingEnabled } from '@allurion/domain';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { InlineLoader } from 'src/components/ui/InlineLoader';
import { ClinicStats } from 'src/domain/Clinic';
import { useClinicSettings } from 'src/hooks/useClinicSettings';
import { useClinicStats } from 'src/hooks/useClinicStats';
import { useCurrentProvider } from 'src/hooks/useCurrentProvider';

import { StatsScrollingList } from './StatsScrollingList';

type Props = {
  clinicId: string;
  isLoadingClinicStats: boolean;
};

export default function ClinicStatCards({ clinicId, isLoadingClinicStats }: Props) {
  const { settings } = useClinicSettings(clinicId);
  const { isAdmin } = useCurrentProvider();
  const { clinicStats, providerStats } = useClinicStats(clinicId);

  const showProviderStats = !isAdmin && isPatientGroupingEnabled(settings);
  const data = showProviderStats ? providerStats : clinicStats;
  const latest = useMemo(() => (data ? data[data.length - 1] : ({} as ClinicStats)), [data]);

  const clinicAggregateData = latest
    ? [
        {
          label: (
            <MessageWrapper>
              <FormattedMessage id="total-patients" defaultMessage="Total Patients" />
            </MessageWrapper>
          ),
          value: latest.total_patients,
        },
        {
          label: (
            <MessageWrapper>
              <FormattedMessage id="active-patients" defaultMessage="Active Patients" />
            </MessageWrapper>
          ),
          value: latest.active_patients,
        },
        {
          label: (
            <MessageWrapper>
              <FormattedMessage id="4-month-TBWL" defaultMessage="Avg. 4-Month TBWL" />
            </MessageWrapper>
          ),
          // eslint-disable-next-line max-len, no-nested-ternary
          value: latest.avg_4mo_tbwl ? `${latest.avg_4mo_tbwl.toFixed(1)}%` : '0.0%',
        },
        {
          label: (
            <MessageWrapper>
              <FormattedMessage id="daily-weigh-in" defaultMessage="Daily Weigh-In Rate" />
            </MessageWrapper>
          ),
          value: latest.daily_weigh_rate_clinic
            ? `${(100 * latest.daily_weigh_rate_clinic).toFixed(1)}%`
            : '0.0%',
        },
        {
          label: (
            <MessageWrapper>
              <FormattedMessage id="weekly-weigh-in" defaultMessage="Weekly Weigh-In Rate" />
            </MessageWrapper>
          ),
          value: latest.weekly_weigh_rate_clinic
            ? `${(100 * latest.weekly_weigh_rate_clinic).toFixed(1)}%`
            : '0.0%',
        },
        {
          label: (
            <MessageWrapper>
              <FormattedMessage id="monthly-weigh-in" defaultMessage="Monthly Weigh-In Rate" />
            </MessageWrapper>
          ),
          value: latest.monthly_weigh_rate_clinic
            ? `${(100 * latest.monthly_weigh_rate_clinic).toFixed(1)}%`
            : '0.0%',
        },
      ]
    : [];

  return (
    <Container>
      {isLoadingClinicStats && !latest ? (
        <InlineLoader />
      ) : (
        <>
          <Title>
            <FormattedMessage
              id="clinic-page.analytics-header"
              defaultMessage="Your patients trends"
            />
          </Title>

          <StatsScrollingList data={clinicAggregateData} />
        </>
      )}
    </Container>
  );
}

const Container = styled.div`
  margin-top: 40px;
  max-width: 100%;
`;

const MessageWrapper = styled.span`
  color: ${({ theme }) => theme.colors.Primary} !important;
`;

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.Primary};
  font-size: 22px;
  line-height: 25.48px;
  /* font-weight: 700px; */
  font-weight: bolder;
  height: 48px;
`;
