import { isPatientGroupingEnabled } from '@allurion/domain';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { ActivePatientsLineChart } from 'src/charts/ActivePatientsLineAreaChart/ActivePatientsChart';
import { useClinic } from 'src/hooks/useClinic';
import { useClinicSettings } from 'src/hooks/useClinicSettings';

type Props = {
  data: null | any[];
  clinicId: string;
};

export function ActivePatientsGraph({ data, clinicId }: Props) {
  const formattedData = useMemo(
    () =>
      data?.map((dataItem: any) => ({
        x: new Date(dataItem.calc_date).getTime(),
        y: dataItem.active_patients,
      })),
    [data]
  );

  const intl = useIntl();
  const { clinic } = useClinic(clinicId);
  const { settings } = useClinicSettings(clinicId);
  const clinicName = clinic?.post_title;

  return (
    <Container>
      <Title>
        {isPatientGroupingEnabled(settings)
          ? intl.formatMessage(
              {
                id: 'active-patients-for-clinic',
                defaultMessage: 'Active Patients for {clinicName}',
              },
              {
                clinicName,
              }
            )
          : intl.formatMessage({
              id: 'active-patients',
              defaultMessage: 'Active Patients',
            })}
        {}
      </Title>
      <div
        style={{
          width: '95%',
          height: '90%',
        }}
      >
        {formattedData && (
          <ActivePatientsLineChart id="active-patients-graph" data={formattedData} />
        )}
      </div>
    </Container>
  );
}

const Container = styled.div`
  background-color: #fff;
  width: 100%;
  margin-top: 30px;
  padding-top: 15px;
  padding-left: 15px;
  padding-bottom: 15px;

  ${({ theme }) => theme.media.min.tablet`
    padding-left: 22px;
    padding-right: 22px;
    padding-top: 47px;
    padding-bottom: 40px;
    margin-top: 54px;
  `}
`;

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.Primary};
  font-size: 11px;
  line-height: 16px;
  padding-left: 22px;
  padding-right: 22px;

  ${({ theme }) => theme.media.min.tablet`
    font-size: 20px;
    line-height: 19.6px;
    padding: 0;
    margin-bottom: 40px;
    margin-left: 60px;
  `}
`;
