import { HeightUnit, WeightUnit, isAdmin, isCoach, isStaff, isSuperAdmin } from '@allurion/domain';
import { ONE_MINUTE_IN_MS } from '@allurion/utils';
import useSWR, { preload } from 'swr';

import { InsightsApi } from 'src/api/Api';
import { Provider } from 'src/domain/Provider';

import { useCurrentUser } from './useCurrentUser';

export function preloadCurrentProvider() {
  return preload<Provider>('/auth/provider', getProviderByAuth);
}

export function useCurrentProvider() {
  const { user } = useCurrentUser();

  const url = user.email && '/auth/provider';
  const { data, error, isValidating } = useSWR(url, getProviderByAuth, {
    revalidateOnFocus: false,
    suspense: true,
    dedupingInterval: ONE_MINUTE_IN_MS,
  });

  const usesKg = data?.UnitsPreference === 'kg';

  return {
    isLoading: isValidating,
    provider: data,
    isAdmin: Boolean(data && isAdmin(data.RoleID)),
    isStaff: Boolean(data && isStaff(data.RoleID)),
    isSuperAdmin: Boolean(data && isSuperAdmin(data.RoleID)),
    isCoach: Boolean(data && isCoach(data.RoleID)),
    usesKg: usesKg,
    weightUnit: (data?.UnitsPreference ?? 'kg') as WeightUnit,
    heightUnit: usesKg ? HeightUnit.Cm : HeightUnit.In,
    currentProviderIdentity: data?.CognitoUserName,
    error,
  };
}

export function getProviderByAuth() {
  return InsightsApi.get<Provider>('/auth/provider');
}
