import { formatWeight, isManualWeightEntryEnabled } from '@allurion/domain';
import { Table, Tooltip, TrashCanIcon, useConfirmationDialog } from '@allurion/ui';
import { formatPercentage, isNotNull, toLocalizedDate } from '@allurion/utils';
import { useState, useCallback } from 'react';
import { useIntl } from 'react-intl';

import { TrackedIconButton } from 'src/analytics/TrackedUI';
import { Pagination } from 'src/components/Pagination';
import { Loader } from 'src/components/ui/Loader';
import { toastError, toastSuccess } from 'src/components/ui/toasts';
import weightEntryMessages from 'src/components/WeightEntry/weight-entry-messages';
import { PatientWeight } from 'src/domain/patient/Patients';
import { fixNumbers } from 'src/helpers/convertions';
import { useClinicSettings } from 'src/hooks/useClinicSettings';
import { useCurrentProvider } from 'src/hooks/useCurrentProvider';
import { useDevice } from 'src/hooks/useDevice';
import { useLocale } from 'src/hooks/useLocale';
import { usePatientWeight } from 'src/hooks/usePatientWeight';
import globalMessages from 'src/messages/global.messages';

import messages from './datatable-messages';
import { PatientScaleDataCards } from './PatientScaleDataCards';

import styles from './PatientScaleData.module.scss';

type Props = {
  patientId: string;
  clinicId?: string;
  reloadData?: () => void;
  sortBy: string;
  setSortBy: (sortBy: string) => void;
  sortedPatientWeightData: PatientWeight[];
};

export function PatientScaleDataTable({
  patientId,
  clinicId,
  sortedPatientWeightData,
  sortBy,
  setSortBy,
}: Props) {
  const intl = useIntl();
  const { locale } = useLocale();
  const { askConfirmationPromise, ConfirmationDialog } = useConfirmationDialog();

  const { deletePatientWeightData } = usePatientWeight(patientId);
  const { weightUnit } = useCurrentProvider();
  const [isDeleting, setIsDeleting] = useState(false);
  const { settings } = useClinicSettings(clinicId);
  const { isMobile } = useDevice();

  const [page, setPage] = useState(1);
  const defaultPageSize = 10;
  const [pageSize, setPageSize] = useState(defaultPageSize);

  const paginatedWeightData = sortedPatientWeightData.slice((page - 1) * pageSize, page * pageSize);
  const lastPage = Math.ceil(sortedPatientWeightData.length / pageSize);

  const deleteWeightEntry = useCallback(
    async (weightEntryId: string) => {
      const shouldDeleteWeightEntry = await askConfirmationPromise({
        title: intl.formatMessage(weightEntryMessages.deleteConfirmationQuestion),

        confirmText: intl.formatMessage(weightEntryMessages.deleteConfirmationAnswer),
        cancelText: intl.formatMessage(globalMessages.cancel),
        variant: 'danger',
      });

      if (!shouldDeleteWeightEntry) {
        return;
      }

      setIsDeleting(true);
      try {
        await deletePatientWeightData(patientId, weightEntryId);
        toastSuccess(intl.formatMessage(weightEntryMessages.deleteSuccessMessage));
      } catch (error) {
        toastError(intl.formatMessage(weightEntryMessages.deleteErrorMessage));
      }
      setIsDeleting(false);
    },
    [askConfirmationPromise, deletePatientWeightData, intl, patientId]
  );

  const onSortChange = (nextSortBy: string) => {
    setSortBy(nextSortBy);
  };

  return (
    <>
      <ConfirmationDialog />
      <Loader cover isLoading={isDeleting} />
      {sortedPatientWeightData?.length > 0 && (
        <>
          {!isMobile ? (
            <Table
              columns={[
                {
                  field: 'CreatedDate',
                  title: intl.formatMessage(globalMessages.date),
                  formatter: (value: string) =>
                    value ? (
                      <strong className={styles.boldColumn}>
                        {toLocalizedDate(value, locale)}
                      </strong>
                    ) : (
                      'No Date'
                    ),
                },
                {
                  field: 'weight',
                  title: intl.formatMessage(messages.weight),
                  formatter: (value) => (
                    <strong className={styles.boldColumn}>{formatWeight(value, weightUnit)}</strong>
                  ),
                },
                {
                  field: 'BMI',
                  title: intl.formatMessage(messages.bmi),
                  formatter: (value) => (isNotNull(value) ? fixNumbers(value, 1, 1) : '-'),
                },
                {
                  field: 'bodyFatMassPerc',
                  title: intl.formatMessage(messages.bodyFatMassPerc),
                  formatter: (value) => formatPercentage(value),
                },
                {
                  field: 'bodyFatMass',
                  title: intl.formatMessage(messages.bodyFatMass),
                  formatter: (value) => (isNotNull(value) ? formatWeight(value, weightUnit) : '-'),
                },
                {
                  field: 'leanBodyMassPerc',
                  title: intl.formatMessage(messages.leanBodyMassPerc),
                  formatter: (value) => formatPercentage(value),
                },
                {
                  field: 'leanBodyMass',
                  title: intl.formatMessage(messages.leanBodyMass),
                  formatter: (value) => (isNotNull(value) ? formatWeight(value, weightUnit) : '-'),
                },
                {
                  field: 'BMR',
                  title: intl.formatMessage(messages.bmr),
                  formatter: (value) => (isNotNull(value) ? fixNumbers(value, 0, 1) : '-'),
                },
                ...(isManualWeightEntryEnabled(settings)
                  ? [
                      {
                        field: 'weight_source_type',
                        title: intl.formatMessage(messages.weightSourceTypeColumn),
                        formatter: (value: string) => {
                          const translationKey = `${value}WeightSourceType`;
                          const message =
                            (messages as any)?.[translationKey] ?? messages.scaleWeightSourceType;

                          return intl.formatMessage(message);
                        },
                      },
                      {
                        field: 'UserInfoId',
                        title: intl.formatMessage(messages.actionsColumn),
                        formatter: (value: string) => (
                          <Tooltip
                            text={intl.formatMessage(weightEntryMessages.removeWeightEntryTooltip)}
                            placement="bottom"
                          >
                            <TrackedIconButton
                              icon={<TrashCanIcon />}
                              variant="icon"
                              onClick={() => deleteWeightEntry(value)}
                              trackLabel="delete-patient-weight-entry"
                            />
                          </Tooltip>
                        ),
                      },
                    ]
                  : []),
              ]}
              data={paginatedWeightData}
              sortBy={sortBy}
              onSortChange={onSortChange}
            />
          ) : (
            <PatientScaleDataCards
              data={paginatedWeightData}
              deleteWeightEntry={deleteWeightEntry}
            />
          )}

          <Pagination
            page={page}
            setPage={setPage}
            pageSize={pageSize}
            defaultPageSize={defaultPageSize}
            setPageSize={setPageSize}
            lastPage={lastPage}
            pageSizeLabel={intl.formatMessage({
              id: 'patients.page-size-label',
              defaultMessage: 'Patients per page',
            })}
          />
        </>
      )}
    </>
  );
}
