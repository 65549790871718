import { ADMIN_ROLE_ID, STAFF_ROLE_ID } from '@allurion/domain';
import { Card, PageHeader } from '@allurion/ui';
import { Auth } from 'aws-amplify';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { TrackedButton } from 'src/analytics/TrackedUI';
import { getProviderByEmail } from 'src/api/ProviderApi';
import { Loader } from 'src/components/ui/Loader';
import { toastSuccess, toastError } from 'src/components/ui/toasts';
import { useAppNavigate } from 'src/hooks/useAppNavigate';
import { Container, InnerContainer } from 'src/pages/shared-page-elements';
import { Logger } from 'src/services/Logger';

import translations from '../ManageUsers.translations';

export function ResendInvitationPage() {
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const { toClinicProvidersPage } = useAppNavigate();
  const user = (location.state as any)?.user;

  const email = user?.EmailAddress;
  const firstName = user?.FirstName;
  const lastName = user?.LastName;
  const clinicID = user?.ClinicID;
  const locationClinicLabel = user?.ClinicLabel;

  const intl = useIntl();
  const adminLabel = intl.formatMessage(translations.manageUsersAdmin);
  const staffLabel = intl.formatMessage(translations.manageUsersStaff);

  const roleMap = {
    2: { label: adminLabel, value: ADMIN_ROLE_ID },
    3: { label: staffLabel, value: STAFF_ROLE_ID },
  };
  const roleID: keyof typeof roleMap = user?.RoleID.toString();
  const role = roleMap[roleID];
  const clinicName = locationClinicLabel.split('\n').join(', ');

  const handleResendInvite = async () => {
    setIsLoading(true);
    try {
      const provider = await getProviderByEmail(email, clinicID[0]);
      const username = provider?.CognitoUserName;

      await Auth.resendSignUp(username);
      toastSuccess(intl.formatMessage(translations.resendInvitationResendSuccess));
      toClinicProvidersPage(clinicID);
    } catch (error) {
      Logger.captureException(error);
      toastError(intl.formatMessage(translations.resendInvitationResendFailure));
    }
    setIsLoading(false);
  };

  const handleCloseBtn = () => {
    toClinicProvidersPage(clinicID);
  };

  const listItems = [
    {
      label: intl.formatMessage(translations.resendInvitationNameAndLastName),
      value: `${firstName} ${lastName}`,
    },
    { label: intl.formatMessage(translations.resendInvitationClinic), value: clinicName },
    { label: intl.formatMessage(translations.resendInvitationEmail), value: email },
    { label: intl.formatMessage(translations.resendInvitationRole), value: role.label },
  ];

  const btnsList = [
    {
      label: intl.formatMessage(translations.resendInvitationClose),
      onClick: handleCloseBtn,
    },
    {
      show: true,
      label: intl.formatMessage(translations.resendInvitationResendInvite),
      onClick: handleResendInvite,
    },
  ];

  const title = translations.resendInvitationTitle;

  return (
    <>
      <PageHeader
        title={intl.formatMessage({
          id: 'resend-invitation.header',
          defaultMessage: 'Resend Invitation',
          description: 'Resend Invitation page header',
        })}
        onNavButtonClick={() => toClinicProvidersPage(clinicID)}
      />
      <Container>
        <InnerContainer>
          <Card>
            <Title>{intl.formatMessage(title)}</Title>
            <List>
              {listItems.map(({ label, value }) => (
                <li key={`resend-invitation_${label}`}>
                  <Label>{`${label}:`}</Label>
                  <Value>{value}</Value>
                </li>
              ))}
            </List>

            <Controls>
              {btnsList.map(
                ({ show, label, onClick }) =>
                  show && (
                    <TrackedButton
                      key={`resend-invitation_${label}`}
                      disabled={isLoading}
                      label={label}
                      onClick={onClick}
                      trackLabel="resend-invitation"
                    />
                  )
              )}
            </Controls>
          </Card>
          <Loader isLoading={isLoading} />
        </InnerContainer>
      </Container>
    </>
  );
}

const Title = styled.p`
  font-size: 16px;
  line-height: 32px;
  color: #00363f;
`;

const List = styled.ul`
  list-style: none;

  li {
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid lightgrey;
  }
`;

const Label = styled.label`
  color: #335e65;
  font-family: GT-America-Standard-Regular, sans-serif;
  font-size: 13px;
  line-height: 18px;
`;

const Value = styled.div`
  color: #00363f;
  font-family: GT-America-Standard-Medium, sans-serif;
  font-size: 14px;
  line-height: 20px;
`;

const Controls = styled.div`
  margin-top: 60px;
  button {
    margin-right: 2em;
  }
`;
